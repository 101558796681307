<template>
  <div
    v-if="currentItem"
    class="noteItem list-group-item my-2"
    :style="formatNote(currentItem)"
    ref="customNoteItem"
  >
  <!-- Icon for Attached Goal Mark (appears in top left corner in the note with yellow color) -->
    <i
      class="bx bx-podcast"
      :class="goalId ? 'box-icon--yellow' : ''"
      v-if="goalId"
      @click="addAttachment(currentItem.id)"
      name="Attached Goal Mark"
      :title="
        goalId
          ? `Attached to Goal:${fetchGoalWithId(goalId)}`
          : 'Attach with Selected Goal'
      "
    ></i>
    <!-- Note Header -->
    <div class="noteItem-header">
      <!-- When Note is a Image -->
      <div
        class="noteItem-image noteItem-asset"
        v-if="currentItem.value.type === 'image'"
      >
        <img
          class="noteItem-image"
          :src="currentItem.value.imageNote || currentItem.value.note"
          :alt="currentItem.value.imageAlt || 'Image from URL'"
        />
      </div>
      <!-- When Note is a Video -->
      <div
        class="noteItem-image noteItem-asset"
        v-else-if="currentItem.value.type === 'video'"
      >
        <iframe
          :src="
            videoEmbedURLFromURL(
              currentItem.value.NoteUrl || currentItem.value.note
            )
          "
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
      <!-- When Note is a Link/Page -->
      <div
        class="noteItem-image noteItem-asset"
        v-else-if="currentItem.value.type === 'page'"
      >
        <a :href="currentItem.value.noteUrl" target="_blank">
          <h5>
            BookMark:
            {{ currentItem.value.title ? currentItem.value.title : "No Title" }}
          </h5>
        </a>
      </div>
      <!-- When note is a screen Recording -->
      <div
        class="noteItem-image noteItem-asset"
        v-else-if="currentItem.value.type === 'recording'"
      >
        <video class="noteItem-video" controls crossorigin="" preload="auto">
          <source :src="currentItem.value.noteUrl" />
          <!-- <track
            label="English"
            kind="subtitles"
            srclang="en"
            :src="currentItem.value.captionUrl"
            default
          /> -->
        </video>

        <p>{{ currentItem.value.caption }}</p>
      </div>
      <!-- When its just a markdown Note(it is a else case cover every unique note type) -->
      <div v-else class="markdown-body">
         <div
          class="showAll"
          :text="compiledMarkdownText(currentItem.value.note)"
        ></div>
        <read-more
          v-if="!showAll"
          more-str="read more"
          :text="compiledMarkdownText(currentItem.value.note)"
          link="#"
          less-str="read less"
          :max-chars="150"
        ></read-more>
        <div
          class="showAll"
          v-else
          v-html="compiledMarkdownText(currentItem.value.note)"
        ></div>
      </div>
      <!-- Note Toolbar -->
      <div class="noteItem-header-title noteItem-asset">
        <div class="noteItem__preference-box">
          <div class="noteItem-preference">
            <!-- Goal Preference -->
            <div class="noteItem-preference__btn">
              <!-- Attach a Goal Icon -->
              <i
                class="noteItem-preference__icon bx bx-podcast"
                :class="goalId ? 'box-icon--yellow' : ''"
                v-if="
                  isAccess(currentItem.actor.username) &&
                  isAttachmentRequested &&
                  !goalId
                "
                @click="addAttachment(currentItem)"
                name="Attach Goal"
                :title="
                  goalId
                    ? `Attached to Goal:${goalId}`
                    : 'Attach with Selected Goal'
                "
              ></i>
              <!-- Remove a Goal Icon -->
              <i
                class="noteItem-preference__icon bx bx-podcast"
                :class="goalId ? 'box-icon--danger' : ''"
                v-if="isAccess(currentItem.actor.username) && goalId"
                @click="removeAttachment(currentItem)"
                name="Remove Goal"
                :title="
                  goalId
                    ? `Remove this Attachment from: ${fetchGoalWithId(goalId)}`
                    : 'Attach with Selected Goal'
                "
              ></i>
            </div>
            <!-- Edit Note Tool -->
            <div class="noteItem-preference__btn">
              <i
                class="noteItem-preference__icon bx bx-edit-alt"
                v-if="isAccess(currentItem.actor.username)"
                @click="showform(currentItem.id, currentItem.value.note)"
                name="edit-alt"
                title="Edit"
              ></i>
            </div>
            <div class="noteItem-preference__btn">
              <i
                class="noteItem-preference__icon bx bx-cloud-snow"
                @click="addNoteToGraph(currentItem)"
                name="cloud-snow"
                title="Add to Graph"
              ></i>
            </div>
            <!-- External Link tool -->
            <div class="noteItem-preference__btn">
              <i
                class="noteItem-preference__icon bx bx-link-external"
                @click="
                  goToCurrentLink({
                    link:
                      currentItem.value.noteUrl ||
                      currentItem.value.imageNote ||
                      currentItem.value.note,
                    id: currentItem.id,
                  })
                "
                name="link-external"
                title="Go To Source"
              >
              </i>
            </div>
            <!-- Remove Note Tool -->
            <div class="noteItem-preference__btn">
              <i
                class="noteItem-preference__icon bx bx-trash"
                v-if="isAccess(currentItem.actor.username)"
                v-on:click="removeItem(currentItem)"
                name="trash"
                title="Delete"
              ></i>
            </div>

            <!-- Copy to Clipboard Icon -->
            <div class="noteItem-preference__btn">
              <i
                class="noteItem-preference__icon bx bx-copy"
                @click="copyToClipboard(currentItem.value.note)"
                name="copy"
                title="Copy to Clipboard"
              ></i>
            </div>

          </div>
          <div class="noteItem-name">
            <span class="noteItem-header-date badge link_highlight">
              <span>@{{ currentItem.actor.username }}</span>
              <br />
              <span>{{ timesAgo(currentItem.value.timestamp) }}</span>
            </span>
          </div>
        </div>

        <div
          v-bind:class="{ displays: isActive }"
          v-if="currentlyEditing === currentItem.id"
        >
          <div class="form-group">
            <Editor :defaultContent="customNote" :onContentChange="onContentChange" />
          </div>
          <div class="d-grid gap-2 mt-2">
            <button
              class="ed-btn__blim ed-btn__sm"
              @click="updateNote(currentItem.id)"
            >
              save note
            </button>
            <button class="ed-btn ed-btn__sm" @click="closenote">
              close note
            </button>
          </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'mavon-editor/dist/css/index.css';
import { mapActions } from 'vuex';
import moment from 'moment';
import marked from 'marked';
import startToEnd from '@/utils/startToEnd';
import exploreOps from '@/common/widgets/GraphExploreWidget/exploreOps';
import smartQueryMode from '@/common/core/Graph/query/smartQueryMode';
import mavenEditorConfigs from '@/config/mavenEditor';
import Editor from '@/common/components/Editor.vue';

export default {
  name: 'NoteDetails',
  components: {
    Editor,
  },
  methods: {
    copyToClipboard(content) {
      navigator.clipboard.writeText(content);
    },
    runCode() {},
    updateNote(id) {
      // alert("update")
      const noteObj = {
        note: this.customNote,
        id,
        type: 'note',
      };
      this.$store.dispatch('updateNoteDispatch', noteObj).then((data) => {
        this.$socket.emit('update_note', data);
      });
      this.closenote();
    },
    isExistDl(note) {
      return note.includes('$ed');
    },
    executeCode(note) {
      const codePayload = startToEnd({
        str: note,
        start: '$ed',
        end: 'ed$',
      });
      const finalCode = codePayload[0].replace('mode:smart', '').trim();
      const globalOperations = smartQueryMode(finalCode);
      if (globalOperations.createList !== undefined) {
        exploreOps.queryOperation(globalOperations.createList);
      }
    },

    isAccess(username) {
      return (
        this.userNameState === username
        || this.$store.state.admin.includes(this.userNameState)
      );
    },

    closenote() {
      // alert(id);
      // alert('close note');
      this.isActive = true;
    },
    showform(id, note) {
      // alert(id);
      this.customNote = note;
      this.currentlyEditing = id;
      this.isActive = false;
    },
    ...mapActions({
      notesFetch: 'notesFetch',
      addToNote: 'addToNote',
    }),
    compiledMarkdownText(textValue) {
      return marked(textValue);
    },
    isMine(item) {
      return item.actor?.username === this.$store.state.auth.user.username;
    },
    addNoteToGraph(item) {
      this.$store.dispatch('gmodule/addNoteToGraph', item);
    },
    formatNote(item) {
      const style = {};
      if (this.isMine(item)) {
        style.backgroundColor = '#f5f5f5';
      }
      return style;
    },
    removeItem(currentItem) {
      this.$store.dispatch('deleteNote', currentItem).then((id) => {
        this.$socket.emit('delete_note', id);
      });
    },
    timesAgo(date) {
      if (date) return moment(date).fromNow();
      return '';
    },
    addNoteFormToggle() {
      this.isAddNote = !this.isAddNote;
    },
    dateWithinOneWeek(date) {
      if (date) return moment(date).isSameOrAfter(moment().subtract(2, 'week'));
      return false;
    },
    videoEmbedURLFromURL(url) {
      if (url) {
        const regex = /^https?:\/\/(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:\S+)?$/;
        const match = url.match(regex);
        if (match && match[1].length === 11) {
          return `https://www.youtube.com/embed/${match[1]}?rel=0`;
        }
      }
      return '';
      // ^.*(youtu.be\/|v\/|embed\/|watch\?|youtube.com\/user\/[^#]*#([^\/]*?\/)*)\??v?=?([^#\&\?]*).*
    },
    forceRefresh() {
      this.notesFetch();
    },
    createNote() {
      const value = {
        note: this.customNote,
        noteUrl: 'thoughtjumper-client.netlify.app/',
        type: 'note',
        timestamp: moment().format(),
      };

      this.addToNote(value).then(() => {
        this.customNote = '';
      });
    },
    goToCurrentLink({ link, id }) {
      if (link.includes(process.env.VUE_APP_DOMAIN)) {
        this.$router.push({ name: 'note_page', params: { id } });
      } else {
        const anchor = document.createElement('a');
        anchor.href = link;
        anchor.target = '__blank';
        anchor.rel = 'nofollow';
        anchor.click();
      }
    },
    addAttachment(currentNote) {
      this.$store.dispatch('goals/updateAttachment', {
        goal: { id: this.goalId },
        query: {
          attachment: { push: currentNote.id },
        },
        additionals: {
          note: currentNote.value.note,
        },
      });
    },
    removeAttachment(currentNote) {
      this.$store.dispatch('goals/updateAttachment', {
        goal: { id: this.goalId },
        query: {
          attachment: { delete: currentNote.id },
        },
        additionals: {
          note: currentNote.value.note,
        },
      });
    },
    fetchGoalWithId() {
      const goal = this.$store.getters['goals/fetchGoalWithId'](this.goalId);
      return goal.goal;
    },
    onContentChange(val) {
      this.customNote = val;
    },
  },
  data() {
    return {
      isActive: false,
      currentlyEditing: '',
      isAddNote: false,
      customNote: '',
      mavonToolbar: mavenEditorConfigs.mavonToolbar,
    };
  },
  props: ['noteId', 'note', 'showAll', 'goalId'],
  computed: {
    userNameState() {
      return this.$store.state.auth.user.username;
    },
    currentItem() {
      if (this.note) {
        return this.note;
      }
      if (this.noteId) {
        return this.$store.state.notes.data.find(
          (item) => item.id === this.noteId,
        );
      }
      return null;
    },
    isAttachmentRequested() {
      if (
        this.$store.state.goals.selectedGoal
        && this.$store.state.goals.selectedGoal.id
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/abstracts/variables";
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.displays {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.topbar {
  display: flex;
  justify-content: space-between;

  &--recent {
    font-size: 1.5rem;
  }
}
.custom-box-css {
  transition: all 0.5s;
  &:hover {
    cursor: pointer;
    transition: all 0.5s;
  }
}
#note-comp {
  padding: 20px 20px;
  // height: auto;
  position: relative;
  height: auto;
  // overflow: auto;
}

.allNoteItems {
  position: relative;
  display: flex;
  flex-direction: column;
  // margin-top: 20px;
  overflow: scroll;
  overflow-x: hidden;

  .noteItem {
    padding: 0.8rem;
    padding-bottom: 4rem;
    // &:hover > .noteItem-preference {
    //   display: flex;
    // }
  }

  .noteItem.list-group-item {
    // margin-bottom: 5;
    scroll-snap-align: end;
    // padding-bottom: 4rem;

    &:hover .noteItem-preference {
      display: flex;
      align-items: center;
    }

    .noteItem-video,
    .noteItem-image {
      width: 100%;
      height: auto;
    }

    .noteItem__preference-box {
      margin: 0.5rem;
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      justify-content: space-between;
      padding: 0 0.5rem;
    }

    .noteItem-preference {
      display: none;
      font-size: 1.5rem;

      &__btn {
        margin-left: 0.5rem;
        cursor: pointer;
      }

      &__icon {
        fill: $color-grey-1;
        transition: all 0.3s ease-in-out;
        &:hover {
          fill: $gray;
          transform: translateY(-3px);
        }
      }
    }

    .noteItem-name {
      margin-left: auto;
    }

    .noteItem-header {
      // display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 8px;

      &-title div p p a {
        // border: 1px solid red;
        font-weight: bolder;
      }

      .noteItem-asset {
        max-height: 100%;
        max-width: 100%;
        // css fit image in div

        & > img {
          max-width: 100%;
          max-height: 100%;
        }
        iframe {
          height: 315px;
          width: 560px;
          max-width: 100%;
          max-height: 100%;
        }
      }
      .deleteBtn {
        width: 30px;
        height: 30px;
        cursor: pointer;
        fill: $color-grey-1;
        transition: all 0.3s ease-out;
        &:hover {
          fill: $gray;
          background: #e0e0e0;
          border-radius: 5rem;
        }
      }
    }
    .noteItem-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .noteItem-header-date {
        text-align: right;
        display: inline-block;
      }
    }
  }
}
.noteItem-preference {
  display: flex;
  font-size: 1.5rem;

  &__btn {
    margin-left: 0.5rem;
    cursor: pointer;
  }

  &__icon {
    fill: $color-grey-1;
    transition: all 0.3s ease-in-out;
    &:hover {
      fill: $gray;
      transform: translateY(-3px);
    }
  }
}
iframe {
  min-height: 20vh;
  width: 100%;
}
.extra-btns {
  display: flex;
  gap: 0.2em;
}
.link_highlight {
  color: #9b9595;
  font-size: 0.85rem;
  font-style: italic;
  font-weight: 500;
  cursor: default;
}
.link_highlight:hover {
  color: #545151;
}
.ed-btn-share {
  padding: 10px;
  background: rgba(154, 160, 166, 0);
  transition: all ease-in-out 200ms;
  border-radius: 50%;
}
.ed-btn-share:hover {
  cursor: pointer;
  opacity: 0.87;
  background: rgba(154, 160, 166, 0.157);
}
</style>
