var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentItem)?_c('div',{ref:"customNoteItem",staticClass:"noteItem list-group-item my-2",style:(_vm.formatNote(_vm.currentItem))},[(_vm.goalId)?_c('i',{staticClass:"bx bx-podcast",class:_vm.goalId ? 'box-icon--yellow' : '',attrs:{"name":"Attached Goal Mark","title":_vm.goalId
        ? ("Attached to Goal:" + (_vm.fetchGoalWithId(_vm.goalId)))
        : 'Attach with Selected Goal'},on:{"click":function($event){return _vm.addAttachment(_vm.currentItem.id)}}}):_vm._e(),_c('div',{staticClass:"noteItem-header"},[(_vm.currentItem.value.type === 'image')?_c('div',{staticClass:"noteItem-image noteItem-asset"},[_c('img',{staticClass:"noteItem-image",attrs:{"src":_vm.currentItem.value.imageNote || _vm.currentItem.value.note,"alt":_vm.currentItem.value.imageAlt || 'Image from URL'}})]):(_vm.currentItem.value.type === 'video')?_c('div',{staticClass:"noteItem-image noteItem-asset"},[_c('iframe',{attrs:{"src":_vm.videoEmbedURLFromURL(
            _vm.currentItem.value.NoteUrl || _vm.currentItem.value.note
          ),"frameborder":"0","allowfullscreen":""}})]):(_vm.currentItem.value.type === 'page')?_c('div',{staticClass:"noteItem-image noteItem-asset"},[_c('a',{attrs:{"href":_vm.currentItem.value.noteUrl,"target":"_blank"}},[_c('h5',[_vm._v(" BookMark: "+_vm._s(_vm.currentItem.value.title ? _vm.currentItem.value.title : "No Title")+" ")])])]):(_vm.currentItem.value.type === 'recording')?_c('div',{staticClass:"noteItem-image noteItem-asset"},[_c('video',{staticClass:"noteItem-video",attrs:{"controls":"","crossorigin":"","preload":"auto"}},[_c('source',{attrs:{"src":_vm.currentItem.value.noteUrl}})]),_c('p',[_vm._v(_vm._s(_vm.currentItem.value.caption))])]):_c('div',{staticClass:"markdown-body"},[_c('div',{staticClass:"showAll",attrs:{"text":_vm.compiledMarkdownText(_vm.currentItem.value.note)}}),(!_vm.showAll)?_c('read-more',{attrs:{"more-str":"read more","text":_vm.compiledMarkdownText(_vm.currentItem.value.note),"link":"#","less-str":"read less","max-chars":150}}):_c('div',{staticClass:"showAll",domProps:{"innerHTML":_vm._s(_vm.compiledMarkdownText(_vm.currentItem.value.note))}})],1),_c('div',{staticClass:"noteItem-header-title noteItem-asset"},[_c('div',{staticClass:"noteItem__preference-box"},[_c('div',{staticClass:"noteItem-preference"},[_c('div',{staticClass:"noteItem-preference__btn"},[(
                _vm.isAccess(_vm.currentItem.actor.username) &&
                _vm.isAttachmentRequested &&
                !_vm.goalId
              )?_c('i',{staticClass:"noteItem-preference__icon bx bx-podcast",class:_vm.goalId ? 'box-icon--yellow' : '',attrs:{"name":"Attach Goal","title":_vm.goalId
                  ? ("Attached to Goal:" + _vm.goalId)
                  : 'Attach with Selected Goal'},on:{"click":function($event){return _vm.addAttachment(_vm.currentItem)}}}):_vm._e(),(_vm.isAccess(_vm.currentItem.actor.username) && _vm.goalId)?_c('i',{staticClass:"noteItem-preference__icon bx bx-podcast",class:_vm.goalId ? 'box-icon--danger' : '',attrs:{"name":"Remove Goal","title":_vm.goalId
                  ? ("Remove this Attachment from: " + (_vm.fetchGoalWithId(_vm.goalId)))
                  : 'Attach with Selected Goal'},on:{"click":function($event){return _vm.removeAttachment(_vm.currentItem)}}}):_vm._e()]),_c('div',{staticClass:"noteItem-preference__btn"},[(_vm.isAccess(_vm.currentItem.actor.username))?_c('i',{staticClass:"noteItem-preference__icon bx bx-edit-alt",attrs:{"name":"edit-alt","title":"Edit"},on:{"click":function($event){return _vm.showform(_vm.currentItem.id, _vm.currentItem.value.note)}}}):_vm._e()]),_c('div',{staticClass:"noteItem-preference__btn"},[_c('i',{staticClass:"noteItem-preference__icon bx bx-cloud-snow",attrs:{"name":"cloud-snow","title":"Add to Graph"},on:{"click":function($event){return _vm.addNoteToGraph(_vm.currentItem)}}})]),_c('div',{staticClass:"noteItem-preference__btn"},[_c('i',{staticClass:"noteItem-preference__icon bx bx-link-external",attrs:{"name":"link-external","title":"Go To Source"},on:{"click":function($event){return _vm.goToCurrentLink({
                  link:
                    _vm.currentItem.value.noteUrl ||
                    _vm.currentItem.value.imageNote ||
                    _vm.currentItem.value.note,
                  id: _vm.currentItem.id,
                })}}})]),_c('div',{staticClass:"noteItem-preference__btn"},[(_vm.isAccess(_vm.currentItem.actor.username))?_c('i',{staticClass:"noteItem-preference__icon bx bx-trash",attrs:{"name":"trash","title":"Delete"},on:{"click":function($event){return _vm.removeItem(_vm.currentItem)}}}):_vm._e()]),_c('div',{staticClass:"noteItem-preference__btn"},[_c('i',{staticClass:"noteItem-preference__icon bx bx-copy",attrs:{"name":"copy","title":"Copy to Clipboard"},on:{"click":function($event){return _vm.copyToClipboard(_vm.currentItem.value.note)}}})])]),_c('div',{staticClass:"noteItem-name"},[_c('span',{staticClass:"noteItem-header-date badge link_highlight"},[_c('span',[_vm._v("@"+_vm._s(_vm.currentItem.actor.username))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.timesAgo(_vm.currentItem.value.timestamp)))])])])]),(_vm.currentlyEditing === _vm.currentItem.id)?_c('div',{class:{ displays: _vm.isActive }},[_c('div',{staticClass:"form-group"},[_c('Editor',{attrs:{"defaultContent":_vm.customNote,"onContentChange":_vm.onContentChange}})],1),_c('div',{staticClass:"d-grid gap-2 mt-2"},[_c('button',{staticClass:"ed-btn__blim ed-btn__sm",on:{"click":function($event){return _vm.updateNote(_vm.currentItem.id)}}},[_vm._v(" save note ")]),_c('button',{staticClass:"ed-btn ed-btn__sm",on:{"click":_vm.closenote}},[_vm._v(" close note ")])])]):_vm._e()])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }